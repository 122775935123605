import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { CMSLink } from '@components/CMSLink';
import { ChevronLeft } from '@icon/ChevronLeft';
import { ChevronRight } from '@icon/ChevronRight';
import { getTranslation } from '@helpers/languageTranslations';
import { useTranslation } from '@stateManagement/TranslationContext';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { extendedPaginationStyles as classes } from './styleObjects/extendedPaginationStyles';
import { paginate } from '@helpers/pagination';
import { Text } from '@web-for-marketing/react-ui';

interface LinkPagination {
    type?: 'link';
    pageUrlPrefix: string;
    pageUrlEnding?: string;
    hasQuery?: boolean;
    onPageChange?: never;
}

interface ButtonPagination {
    type: 'button';
    onPageChange: (page: number) => void;
    pageUrlPrefix?: never;
    pageUrlEnding?: never;
    hasQuery?: never;
}

type ExtendedPaginationProps = {
    currentPage: number;
    maxRecordsPerPage: number;
    totalRecordCount: number;
    connect2024?: boolean;
} & (LinkPagination | ButtonPagination);

export function ExtendedPagination({
    currentPage,
    maxRecordsPerPage,
    totalRecordCount,
    type,
    pageUrlPrefix,
    pageUrlEnding,
    hasQuery,
    onPageChange,
    connect2024,
}: ExtendedPaginationProps): JSX.Element {
    const { selectedLanguageTranslation } = useTranslation();
    const [isMobile] = useAtom(isMobileAtom);
    const paginationTranslation = useMemo(
        () => getTranslation(selectedLanguageTranslation, 'extendedPagination'),
        [selectedLanguageTranslation]
    );
    const lastPage = useMemo(
        () => Math.ceil(totalRecordCount / maxRecordsPerPage),
        [maxRecordsPerPage, totalRecordCount]
    );
    const pages = useMemo(() => paginate(currentPage, lastPage, isMobile), [currentPage, lastPage, isMobile]);

    const previousDisabled = currentPage <= 1;
    const nextDisabled = currentPage >= lastPage;

    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;

    function getPageUrl(page: number): string {
        return hasQuery
            ? `${pageUrlPrefix}&page=${page}`
            : `${pageUrlPrefix}${page}/${pageUrlEnding ? pageUrlEnding : ''}`;
    }

    return (
        <Grid container css={classes.container} data-testid='extendedPagination'>
            {type === 'button' ? (
                <Text
                    component='button'
                    onClick={() => onPageChange(previousPage)}
                    variant='body1'
                    css={[
                        classes.paginationItem(connect2024),
                        previousDisabled ? classes.disabledTextLink(connect2024) : classes.textLink(connect2024),
                    ]}
                    disabled={previousDisabled}
                    aria-label='Go to previous page'
                >
                    <ChevronLeft css={classes.chevronLeft} /> {paginationTranslation.previous}
                </Text>
            ) : (
                <Text
                    component={CMSLink}
                    variant='body1'
                    css={[
                        classes.paginationItem(connect2024),
                        previousDisabled ? classes.disabledTextLink(connect2024) : classes.textLink(connect2024),
                    ]}
                    href={previousDisabled ? undefined : getPageUrl(previousPage)}
                    tabIndex={previousDisabled ? -1 : 0}
                    aria-hidden={previousDisabled}
                    aria-label='Go to previous page'
                >
                    <ChevronLeft css={classes.chevronLeft} /> {paginationTranslation.previous}
                </Text>
            )}
            {!isMobile ? (
                <ul css={classes.pageList}>
                    {pages.map((page, index) => {
                        const onCurrentPage = page === currentPage;
                        return (
                            <li key={index} css={classes.pageListItem}>
                                {page ? (
                                    type === 'button' ? (
                                        <Text
                                            component='button'
                                            onClick={() => onPageChange(page)}
                                            disabled={onCurrentPage}
                                            variant='body1'
                                            css={[
                                                classes.paginationItem(connect2024),
                                                onCurrentPage
                                                    ? classes.selectedPageListLink(connect2024)
                                                    : classes.pageListLink(connect2024),
                                            ]}
                                            aria-label={`Go to page ${page}`}
                                        >
                                            {page}
                                        </Text>
                                    ) : (
                                        <Text
                                            component={CMSLink}
                                            variant='body1'
                                            css={[
                                                classes.paginationItem(connect2024),
                                                onCurrentPage
                                                    ? classes.selectedPageListLink(connect2024)
                                                    : classes.pageListLink(connect2024),
                                            ]}
                                            aria-label={`Go to page ${page}`}
                                            href={onCurrentPage ? undefined : getPageUrl(page)}
                                            tabIndex={onCurrentPage ? -1 : 0}
                                            aria-hidden={onCurrentPage}
                                        >
                                            {page}
                                        </Text>
                                    )
                                ) : (
                                    <div css={classes.paginationItem(connect2024)}>...</div>
                                )}
                            </li>
                        );
                    })}
                </ul>
            ) : null}
            {type === 'button' ? (
                <Text
                    component='button'
                    onClick={() => onPageChange(nextPage)}
                    variant='body1'
                    css={[
                        classes.paginationItem(connect2024),
                        nextDisabled ? classes.disabledTextLink(connect2024) : classes.textLink(connect2024),
                    ]}
                    disabled={nextDisabled}
                    aria-label='Go to next page'
                >
                    {paginationTranslation.next} <ChevronRight css={classes.chevronRight} />
                </Text>
            ) : (
                <Text
                    component={CMSLink}
                    variant='body1'
                    css={[
                        classes.paginationItem(connect2024),
                        nextDisabled ? classes.disabledTextLink(connect2024) : classes.textLink(connect2024),
                    ]}
                    href={nextDisabled ? undefined : getPageUrl(nextPage)}
                    tabIndex={nextDisabled ? -1 : 0}
                    aria-hidden={nextDisabled}
                    aria-label='Go to next page'
                >
                    {paginationTranslation.next} <ChevronRight css={classes.chevronRight} />
                </Text>
            )}
            {isMobile ? (
                <Grid item container xs={12} justifyContent='center'>
                    <Text
                        variant='body1'
                        color={connect2024 ? 'inversePrimary' : 'primary'}
                        css={classes.mobilePaginationText}
                    >
                        Page {currentPage} of {lastPage}
                    </Text>
                </Grid>
            ) : null}
        </Grid>
    );
}
