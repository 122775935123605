import { connectColors } from '@components/Connect2024/connectHelpers';
import { v2Colors, getRgbaColor } from '@web-for-marketing/react-core';

export const extendedPaginationStyles = {
    container: {
        justifyContent: 'center',
    },
    pageList: {
        display: 'flex',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        alignItems: 'center',
    },
    paginationItem: (connect?: boolean) =>
        ({
            padding: '1.6rem',
            borderRadius: '0.8rem',
            border: `1px solid ${connect ? 'transparent' : v2Colors.core.snow}`,
            backgroundColor: connect ? connectColors.black : v2Colors.core.snow,
            color: connect ? connectColors.white : v2Colors.core.geotab,
            cursor: 'pointer',
        }) as const,
    textLink: (connect?: boolean) =>
        ({
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: connect ? connectColors.white : v2Colors.core.geotab,
            },
            '&:hover': {
                textDecoration: connect ? 'none' : 'underline',
                backgroundColor: connect ? connectColors.black : v2Colors.core.cloud,
                border: `1px solid ${connect ? connectColors.white : v2Colors.core.cloud}`,
            },
            '&:focus-visible': {
                '& svg': {
                    color: 'currentColor',
                },
                ...(connect
                    ? {
                        border: `1px solid ${connectColors.white}`,
                        outline: `2px solid ${connectColors.white}`,
                        outlineOffset: '-5px',
                    }
                    : {
                        outline: 'none',
                        border: `1px solid ${v2Colors.core.innovation}`,
                        color: v2Colors.core.innovation,
                    }),
            },
        }) as const,
    disabledTextLink: (connect?: boolean) =>
        ({
            display: 'flex',
            alignItems: 'center',
            color: connect ? getRgbaColor(connectColors.white, 0.7) : v2Colors.core.slate,
            pointerEvents: 'none',
            '& svg': {
                color: 'currentColor',
            },
        }) as const,
    pageListLink: (connect?: boolean) =>
        ({
            '&:hover': {
                textDecoration: connect ? 'none' : 'underline',
                backgroundColor: connect ? connectColors.black : v2Colors.core.cloud,
                border: `1px solid ${connect ? connectColors.white : v2Colors.core.cloud}`,
            },
            '&:focus-visible': {
                '& svg': {
                    color: 'currentColor',
                },
                ...(connect
                    ? {
                        border: `1px solid ${connectColors.white}`,
                        outline: `2px solid ${connectColors.white}`,
                        outlineOffset: '-5px',
                    }
                    : {
                        outline: 'none',
                        border: `1px solid ${v2Colors.core.innovation}`,
                        color: v2Colors.core.innovation,
                    }),
            },
        }) as const,
    selectedPageListLink: (connect?: boolean) =>
        ({
            backgroundColor: connect ? connectColors.black : v2Colors.core.geotab,
            color: v2Colors.core.snow,
            border: `1px solid ${connect ? connectColors.white : v2Colors.core.geotab}`,
            pointerEvents: 'none',
        }) as const,
    pageListItem: {
        '&:first-child': {
            marginLeft: '0.8rem',
        },
        marginRight: '0.8rem',
    },
    chevronRight: {
        marginLeft: '2rem',
    },
    chevronLeft: {
        marginRight: '2rem',
    },
    mobilePaginationText: {
        marginTop: '1rem',
    },
} as const;
