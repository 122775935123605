export function paginate(currentPage: number, lastPage: number, isMobile = false): (number | null)[] {
    const maxPages = isMobile ? 5 : 7;
    const maxMiddlePages = maxPages - 2;

    if (lastPage <= maxPages) {
        return Array.from(Array(lastPage).keys()).map((p) => p + 1);
    } else {
        if (lastPage - currentPage <= (isMobile ? 2 : 3)) {
            const lastPages = Array.from(Array(maxMiddlePages).keys())
                .map((p) => lastPage - p)
                .reverse();
            return [1, null, ...lastPages];
        }

        if ((!isMobile && currentPage < maxMiddlePages) || (isMobile && currentPage <= maxMiddlePages)) {
            const firstPages = Array.from(Array(maxMiddlePages).keys()).map((p) => p + 1);
            return [...firstPages, null, lastPage];
        }

        if ((!isMobile && currentPage >= maxMiddlePages) || (isMobile && currentPage > maxMiddlePages)) {
            if (maxMiddlePages - 2 === 1) {
                return [1, null, currentPage, null, lastPage];
            } else {
                const middlePages = Array.from(Array(maxMiddlePages - 2).keys()).map((p) => p + (currentPage - 1));
                return [1, null, ...middlePages, null, lastPage];
            }
        }
    }

    return [];
}
